import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Drawer from '@mui/material/Drawer';
import UserLoginFormPopup from '../UserLoginFormPopup';
import UserSignupFormPopup from '../UserSignupFormPopup';
import { useAuth, useNavigationPopups } from '../../contexts/MainAppContext';
import NavigationUserDropdown from './NavigationUserDropdown';
import { useLocation } from '@reach/router';

const Navigation = (props) => {
  const location = useLocation();

  const [bgNavbar, setBgNavbar] = useState('transparent');
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { logoutUser, isUserLoggedIn } = useAuth();
  const { showLoginPopup, showSignupPopup, isLoginPopupOpen, isSignupPopupOpen, hideAllPopups } = useNavigationPopups();

  const menuOptions = [
    { name: 'Home', link: '/#hero' },
    { name: 'About Author', link: '/#author' },
    { name: 'Journey', link: '/#journey' },
    { name: 'New Chapters', link: '/#new_chapters' },
    { name: 'Testimonial', link: '/#testimonial' },
  ];

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get('showLogin') === 'true') {
      showLoginPopup();
    } else if (urlParams.get('showSignup') === 'true') {
      showSignupPopup();
    }
  }, [location]);

  const handleScroll = () => {
    if (window.pageYOffset > 50) {
      setBgNavbar('rgb(0,0,0,.9)');
    } else {
      setBgNavbar('transparent');
    }
  };

  const handleLogout = async (e) => {
    await logoutUser();
    navigate('/');
  };

  return (
    <>
      <div
        className={`fixed w-screen xl:py-5 py-3 xl:bg-none z-10 xl:px-28 px-7 xl:bg-none xl:bg-opacity-0 navbar-bg-color`}
        style={{ backgroundColor: bgNavbar, transitionProperty: 'background-color', transitionDuration: '0.2s' }}>
        {/* hamburguer tailwind*/}
        <div className="flex items-center justify-between py-0">
          <Link to="/" className="xl:block hidden">
            <StaticImage
              className="w-full xl:max-w-[130px] max-w-[100px] mx-auto xl:mt-1"
              src={'../../images/logo-white.png'}
              formats={['auto', 'webp', 'avif']}
              width={500}
              alt="Girar Logo"
              placeholder="blurred"
            />
          </Link>

          <Link to="/" className="xl:hidden block">
            <StaticImage
              className="w-full xl:max-w-[130px] max-w-[100px] mx-auto xl:mt-1"
              src={'../../images/logo-white.png'}
              formats={['auto', 'webp', 'avif']}
              width={500}
              alt="Girar Logo"
              placeholder="blurred"
            />
          </Link>
          <nav>
            <section
              className="MOBILE-MENU flex lg:hidden"
              onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
            >
              {!isNavOpen ? (
                <div className="HAMBURGER-ICON space-y-2">
                  <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
                  <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
                  <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
                </div>
              ) : null}

              <div className={isNavOpen ? 'showMenuNav bg-black' : 'hideMenuNav'}>
                <div
                  className="CROSS-ICON absolute top-0 right-0 px-5 py-5"
                  onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
                  style={{ color: 'white' }}>
                  <svg
                    className="h-8 w-8 "
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </div>
                {/*navbar links*/}
                <ul className="NAVIGATION-MOBILE-OPEN flex flex-col items-center justify-between min-h-[250px] ">
                  {isUserLoggedIn ? (
                    <li className="flex  align-items-center">
                      <Link to="/dashboard" className="navbar-link mx-auto">
                        Dashboard
                      </Link>
                    </li>
                  ) : null}

                  {menuOptions.map((option, index) => (
                    <li key={index} className="flex  align-items-center">
                      <Link to={option.link} className="navbar-link mx-auto">
                        {option.name}
                      </Link>
                    </li>
                  ))}

                  {!isUserLoggedIn ? (
                    <li className="flex uppercas  align-items-center pt-10">
                      <button
                        onClick={() => showSignupPopup()}
                        className="xl:px-5 mx-auto px-5 button-dark bottom-10 transition-all duration-500 btn-xs font-bold font-opensans">
                        Sign up
                      </button>
                    </li>
                  ) : null}
                  {isUserLoggedIn ? (
                    <li className="flex align-items-center pt-10">
                      <button
                        className="xl:px-5 mx-auto px-5 button-white bottom-10 transition-all duration-500 btn-xs font-bold font-opensans"
                        onClick={handleLogout}>
                        Logout
                      </button>
                    </li>
                  ) : (
                    <li className="flex  align-items-center pt-3">
                      <button
                        onClick={() => showLoginPopup()}
                        className="xl:px-5 mx-auto px-5 button-white bottom-10 transition-all duration-500 btn-xs font-bold font-opensans">
                        Login
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </section>

            <ul className="DESKTOP-MENU hidden lg:flex">
              {menuOptions.map((option, index) => (
                <li key={index} className="flex  align-items-center">
                  <Link to={option.link} className="navbar-link">
                    {option.name}
                  </Link>
                </li>
              ))}

              {!isUserLoggedIn ? (
                <li className="flex uppercas align-items-center">
                  <button
                    onClick={() => showSignupPopup()}
                    className="xl:px-5 ml-5 px-5 button-dark bottom-10 transition-all duration-500 btn-xs font-bold font-opensans">
                    Sign up
                  </button>
                </li>
              ) : null}
              {isUserLoggedIn ? (
                <NavigationUserDropdown></NavigationUserDropdown>
              ) : (
                <li onClick={() => showLoginPopup()} className="flex  align-items-center">
                  <button className="xl:px-5 ml-5 px-5 button-white bottom-10 transition-all duration-500 btn-xs font-bold font-opensans">
                    Login
                  </button>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
      <Drawer anchor={'right'} open={isLoginPopupOpen} onClose={() => hideAllPopups()}>
        <div style={{ width: '100vw', maxWidth: 800, minWidth: 320 }}>
          <UserLoginFormPopup />
        </div>
      </Drawer>

      <Drawer anchor={'right'} open={isSignupPopupOpen} onClose={() => hideAllPopups()}>
        <div style={{ width: '100vw', maxWidth: 800, minWidth: 320 }}>
          <UserSignupFormPopup />
        </div>
      </Drawer>
    </>
  );
};

export default Navigation;
