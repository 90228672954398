import React, {useState, useEffect} from "react";
import UserLoginFormRazorpay from '../components/UserLoginFormRazorPay'
import { Helmet } from 'react-helmet'
import Navigation from "../components/navigation/navigation";
import { navigate } from 'gatsby-link';
import { useCookies } from 'react-cookie';
import OGImage from '../images/favicon-black.png';

const Login = () => {
    const [loggedIn, setLoggedIn] = useState("")
    const [cookies, setCookie] = useCookies(['loggedIn']);
    const url = typeof window !== 'undefined' ? window.location.origin : '';

    useEffect(() => {
        setLoggedIn(cookies.loggedIn);
        if (loggedIn == "true") {
            navigate("/account-razorpay");
        }
    }, [loggedIn]);

    return (
        <>
            <Helmet>
            <link rel="canonical" href={`${url}/login`} />
                <meta http-equiv="Content-Type" content="text/xml; charset=UTF-8" />
                <meta property="og:title" content="Login | Girar World" />
                <meta property="description" content="Girar, go on a journey" />
                <meta property="og:description" content="Girar, go on a journey" />
                <meta property="og:url" content={`${url}/login`} />
                <title>Home | Girar World</title>
                <meta property="og:image" content={`${url}${OGImage}`} />
                <meta name="twitter:url" content={`${url}/login`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@WeltgeistKiran" />
                <meta name="twitter:title" content="Login | Girar World" />
                <meta name="twitter:description" content="Girar, go on a journey" />
                <meta property="twitter:image" content={`${url}${OGImage}`} />
                <meta property="og:type" content="website" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Rokkitt:wght@400;500;600;700;800;900&family=Telex&display=swap" rel="stylesheet" />
            </Helmet>
            <Navigation />
            <UserLoginFormRazorpay />
        </>
    )
}

export default Login;
